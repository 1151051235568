import { getAccountId } from "../utilities/localStorage";

export const isShopifyAvailable = () => {
  const accountId = Number(getAccountId());
  return [
    1300, 1650, 4041, 2703, 10299, 4328, 8062, 8941, 3580, 8941, 7473,
  ].includes(accountId);
};

export const isNewPPMode = () => {
  var accountId = Number(getAccountId());
  return [1300].includes(accountId);
};
