import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../../consts/api";

export const usePatientWallet = (params, options = {}) => {
  const reportData = useQuery(
    ["patientWallet", params],
    async () => {
      return http.get(HTTP_ENDPOINTS.getOldPatientWallet(), { params });
    },
    { select: (data) => data.data.data, ...options },
  );

  return reportData;
};
