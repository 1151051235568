import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CommonDialog } from "../../../../Components/Common";
import confirmModalCasses from "../../../../boxes/ConfirmModal/ConfirmModal.module.scss";
// eslint-disable-next-line import/no-restricted-paths
import { GeServicesList } from "../../../../_legacy/Queries";
import { uiNotification } from "../../../../services/UINotificationService";
import { Button } from "../../../../shared/Button/Button";
import modalClasses from "../../../../shared/Modal/sass/Modal.module.scss";
import { Select } from "../../../../shared/Select/Select";
import { useApiProvidersByClinics } from "../../hooks/useApiProvidersByClinics";
import classes from "../../sass/Event.module.scss";
import { EntityRow } from "../EntityRow";

const convertSchema = yup.object().shape({
  selectedClinic: yup.object().shape({
    id: yup.number().nullable().required("Required").typeError("Required"),
  }),
  selectedProvider: yup.object().shape({
    id: yup.number().nullable().required("Required").typeError("Required"),
  }),
});

const ConvertToAppointmentModal = ({ isOpen, onCancel, history, data }) => {
  const { selectedClinics: allClinics, selectedProviders: allProviders } = data;

  const {
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedClinic:
        allClinics.length === 1
          ? {
              ...allClinics[0],
              label: allClinics[0].name,
              value: allClinics[0].id,
            }
          : null,
      selectedProvider:
        allProviders.length === 1
          ? {
              ...allProviders[0],
              label: allProviders[0].name,
              value: allProviders[0].id,
            }
          : null,
    },
    resolver: yupResolver(convertSchema),
  });

  const { selectedClinic, selectedProvider } = watch();

  const handleSelectClinic = (userSelectedClinic) => {
    const { label, value } = userSelectedClinic;
    setValue(
      "selectedClinic",
      {
        id: value,
        value,
        name: label,
        label,
      },
      {
        shouldValidate: errors.selectedClinic,
      },
    );
  };

  const handleSelectProvider = (userSelectedProvider) => {
    const { label, value } = userSelectedProvider;
    setValue(
      "selectedProvider",
      {
        id: value,
        value,
        name: label,
        label,
      },
      {
        shouldValidate: errors.selectedProvider,
      },
    );
  };

  const { providersDictionary, isProvidersByClinicFetching } =
    useApiProvidersByClinics({
      clinicIds: selectedClinic ? [selectedClinic.id] : [],
    });

  const filteredAvailableProviders = useMemo(() => {
    if (
      selectedClinic &&
      providersDictionary &&
      Object.keys(providersDictionary).length > 0
    ) {
      const availableProviders = allProviders.filter(
        (providerDetails) => providerDetails.id in providersDictionary,
      );

      if (selectedProvider && !(selectedProvider.id in providersDictionary)) {
        setValue("selectedProvider", null);
      }
      return availableProviders;
    }

    return allProviders;
  }, [isProvidersByClinicFetching]);

  const convertToAppoitnemnt = async () => {
    const isValid = await trigger();
    if (isValid) {
      const { id, patient, appointment_notes, appointment_wait_list_services } =
        data;

      const servicesOffered = await GeServicesList({
        providerIds: [selectedProvider.id],
        clinicIds: [selectedClinic.id],
      });

      if (servicesOffered.data.services) {
        const servicesDictionary = Object.assign(
          {},
          ...servicesOffered.data.services.map((serviceDetails) => ({
            [serviceDetails.id]: serviceDetails,
          })),
        );

        history.push(`/appointment/create/${selectedProvider.id}`, {
          id: id,
          patient,
          appointment_notes,
          appointment_wait_list_clinic: selectedClinic.id,
          appointment_wait_list_services: appointment_wait_list_services
            .filter(
              (waitlistService) =>
                waitlistService.service.id in servicesDictionary,
            )
            .map((selectedService) => ({
              id: selectedService.service.id,
              name: selectedService.service.name,
              duration: servicesDictionary[selectedService.service.id].duration,
              isFree: Boolean(
                servicesDictionary[selectedService.service.id].is_service_free,
              ),
              price: parseFloat(
                servicesDictionary[selectedService.service.id].price,
              ),
              gfeRequired: Boolean(
                servicesDictionary[selectedService.service.id].is_gfe_required,
              ),
            })),
        });
      } else {
        uiNotification.error(
          "No services provided by the selected clinic provider.",
        );
      }
    }
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      onConfirm={convertToAppoitnemnt}
      onClose={onCancel}
      title="Convert To Appointment!"
    >
      <div className={classes.convertWaitlistModal}>
        <>
          You can select only one Clinic and only one Provider if you want to
          convert this waitlist to an appointment.
        </>

        <div className={classes.clinicProviderFields}>
          <EntityRow label="Clinic" size="small">
            <Select
              isSearchable
              isError={Boolean(errors.selectedClinic)}
              value={selectedClinic}
              onChange={handleSelectClinic}
              placeholder="Select clinic"
              options={allClinics.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              size="small"
              isFixed
            />
          </EntityRow>

          <EntityRow label="Provider" size="small">
            <Select
              isSearchable
              isError={Boolean(errors.selectedProvider)}
              value={selectedProvider}
              onChange={handleSelectProvider}
              placeholder="Select provider"
              options={filteredAvailableProviders.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              size="small"
              isFixed
            />
          </EntityRow>
        </div>

        <div className={modalClasses.footerNoBorder}>
          <div className={confirmModalCasses.buttons}>
            <Button onClick={convertToAppoitnemnt}>Confirm</Button>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </CommonDialog>
  );
};

ConvertToAppointmentModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  history: PropTypes.object,
  data: PropTypes.object,
};

export default ConvertToAppointmentModal;
