import React, { useState } from "react";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout.js";
import { Header } from "./shared/Header/Header.js";
import { useDownloadExcel } from "./hooks/useDownloadExcel.js";
import { Total } from "./shared/Total/Total.js";
import { StaticReports } from "./StaticReports/StaticReports.js";
import { Box } from "../../../../../shared/Box/Box.js";
import { TableHeading } from "./shared/TableHeading/TableHeading.js";
import { Table } from "./shared/Table/Table.js";
import { useReports } from "./hooks/useReports.js";
import styles from "./styles.module.scss";
import PatientWalletReport from "./StaticReports/PatientWallet/PatientWalletReport.js";

export const ShortTermLiability = () => {
  const { reports, order, period, search, totalLiability } = useReports();
  const downloadExcel = useDownloadExcel({ period, order, search });

  const [selectedRowView, setSelectedRowView] = useState(null);

  const handleRowClick = (row) => {
    const noInvoice = row.invoiceId === null || row.packageId === null;
    if (noInvoice) {
      return;
    }
    setSelectedRowView(row);
  };

  return (
    <ReportLayout>
      {!selectedRowView ? (
        <>
          <Header
            downloadExcel={downloadExcel}
            isReportsLoading={reports.isLoading}
          />
          <Total value={totalLiability} />
          <StaticReports />
          <Box className={styles.tableWrap}>
            <TableHeading
              isReportsLoading={reports.isLoading}
              period={period}
              search={search}
            />
            <Table
              order={order}
              reports={reports}
              handleRowClick={handleRowClick}
            />
          </Box>
        </>
      ) : (
        <PatientWalletReport
          selectedRowView={selectedRowView}
          setSelectedRowView={setSelectedRowView}
          period={period}
        />
      )}
    </ReportLayout>
  );
};
