/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import WaitListMultiselectEntity from "../../pages/Event/components/waitList/WaitListMultiselectEntity";
import { useApiClinics } from "../../pages/Event/hooks/useApiClinics";
import { useApiProvidersByClinics } from "../../pages/Event/hooks/useApiProvidersByClinics";
import { useApiServicesByClinicsAndProviders } from "../../pages/Event/hooks/useApiServicesByClinicsAndProviders";
import { showFormattedDate } from "../../Utils";
import GfeInputs from "../../pages/Event/components/appointment/GfeInputs";
import styles from "../../pages/Event/sass/components/GfeInputs.module.scss";

const FieldDisplay = ({ name, value }) => {
  return (
    <div className="field-display">
      <p className="field-title">{name}:</p>{" "}
      <p className="field-value">{value}</p>
    </div>
  );
};

const ClinicsProvidersServices = ({
  appointment_wait_list_providers,
  appointment_wait_list_services,
  appointment_wait_list_clinics,
  gfeDatas,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const editServicesStatus = useWatch({
    control,
    name: "editServicesStatus",
    defaultValue: false,
  });

  const selectedClinics = useWatch({
    control,
    name: "selectedClinics",
  });

  const selectedProviders = useWatch({
    control,
    name: "selectedProviders",
  });

  const selectedServices = useWatch({
    control,
    name: "selectedServices",
  });

  const editServices = () => {
    setValue("editServicesStatus", !editServicesStatus);
  };

  const { clinics: availableClinicsList } = useApiClinics();

  const isGfeRequired = useMemo(
    () => selectedServices.some((service) => service.gfeRequired),
    [selectedServices],
  );

  const {
    providersByClinic,
    providersDictionary,
    isProvidersByClinicFetching,
  } = useApiProvidersByClinics({
    clinicIds: selectedClinics.map((c) => c.id),
  });

  const {
    servicesByClinicsAndProviders,
    servicesDictionary,
    isServicesByClinicsAndProvidersFetching,
  } = useApiServicesByClinicsAndProviders({
    clinicIds: selectedClinics.map((c) => c.id),
    providerIds: selectedProviders.map((p) => p.id),
  });

  const onClinicsChange = (nextClinics) => {
    setValue("selectedClinics", nextClinics);
  };

  const onProvidersChange = (nextProviders) => {
    setValue("selectedProviders", nextProviders);
  };

  const onServicesChange = (nextServices) => {
    setValue("selectedServices", nextServices);
  };

  useEffect(() => {
    if (!isProvidersByClinicFetching) {
      setValue(
        "selectedProviders",
        selectedProviders.filter((p) => p.id in providersDictionary),
      );
    }
  }, [providersDictionary, isProvidersByClinicFetching]);

  useEffect(() => {
    if (!isServicesByClinicsAndProvidersFetching) {
      setValue(
        "selectedServices",
        selectedServices.filter((s) => s.id in servicesDictionary),
      );
    }
  }, [servicesDictionary, isServicesByClinicsAndProvidersFetching]);

  const selectedClinicsNames = useMemo(
    () =>
      appointment_wait_list_clinics
        .map((clinicDetails) => clinicDetails.clinic.clinic_name)
        .join(", "),
    [appointment_wait_list_clinics],
  );

  const selectedProvidersNames = useMemo(
    () =>
      appointment_wait_list_providers
        .map((providerDetails) => providerDetails.full_name)
        .join(", "),
    [appointment_wait_list_providers],
  );

  const selectedServicedNames = useMemo(
    () =>
      appointment_wait_list_services
        .map((providerDetails) => providerDetails.service.name)
        .join(", "),
    [appointment_wait_list_services],
  );

  return (
    <div>
      {editServicesStatus ? (
        <div className="clinic-provider-edit-form">
          <>
            <WaitListMultiselectEntity
              label="Clinic"
              name="clinics"
              values={selectedClinics}
              onChange={onClinicsChange}
              isError={Boolean(errors.selectedClinics)}
              options={availableClinicsList}
              placeholder="Select clinics"
              size="small"
            />
            <WaitListMultiselectEntity
              label="Provider"
              name="providers"
              values={selectedProviders}
              onChange={onProvidersChange}
              isError={Boolean(errors.selectedProviders)}
              options={providersByClinic}
              placeholder="Select providers"
              size="small"
            />
            <WaitListMultiselectEntity
              label="Service"
              name="services"
              values={selectedServices}
              onChange={onServicesChange}
              isError={Boolean(errors.selectedServices)}
              options={servicesByClinicsAndProviders}
              placeholder="Select services"
              size="small"
            />
            {isGfeRequired && (
              <GfeInputs
                dob={gfeDatas.dob.value}
                state={gfeDatas.dob.state}
                handleDob={(value) => gfeDatas.dob.onChange(value)}
                handleState={(option) => gfeDatas.state.onChange(option)}
                size="small"
                showLabels
                calendarClassName={styles.calendarRight}
              />
            )}
          </>
        </div>
      ) : (
        <div className="clinic-provider-container">
          <a className="easy-link pull-right no-padding" onClick={editServices}>
            <i className="fa fa-pencil-alt"></i> Edit
          </a>
          <FieldDisplay name="Clinic" value={selectedClinicsNames} />
          <FieldDisplay name="Provider" value={selectedProvidersNames} />
          <FieldDisplay name="Service(s)" value={selectedServicedNames} />
          {isGfeRequired && (
            <>
              <FieldDisplay
                name="Date of Birth"
                value={showFormattedDate(gfeDatas.dob.value)}
              />
              <FieldDisplay name="State" value={gfeDatas.state.value} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ClinicsProvidersServices;
