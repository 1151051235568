import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup
      .array()
      .of(
        yup.object({
          date: yup.string().required(),
          patientName: yup.string().required(),
          productName: yup.string().required(),
          unitsCount: yup.number().required(),
          dollarValue: yup.string().required(),
          balanceUnitsCount: yup.number().required(),
          balanceDollarValue: yup.string().required(),
        }),
      )
      .required(),

    stats: yup
      .object({
        total: yup.string().nullable(),
      })
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const reports = unwrapOr(() => res.data.data.data, []);
  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data, {}),
      pageSize: req.pageSize,
    }),
    reports: reports.map((report) => ({
      date: report.date,
      patientName: report.client,
      productName: report.product_name,
      unitsCount: report.units || 0,
      dollarValue: report.dollar_value,
      balanceUnitsCount: report.balance_units || 0,
      balanceDollarValue: report.balance_dollar_value,
      invoiceId: report.invoice_id,
      packageId: report.package_id,
      patientId: report.patient_id,
    })),
    stats: {
      total: res?.data?.data?.total_liability || null,
    },
  };
};

function createSalesExpiredLiabilityReportQueryKey({
  fromDate,
  toDate,
  pageSize,
}) {
  return [QUERY_KEYS.expiredLiabilityReport, fromDate, toDate, pageSize];
}

export function useSalesExpiredLiabilityReportQuery({
  payload = {},
  options = {},
} = {}) {
  const fromDate = unwrapOr(
    () => moment(payload.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(payload.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createSalesExpiredLiabilityReportQueryKey({
      fromDate,
      toDate,
      pageSize: payload.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.post(
        HTTP_ENDPOINTS.createExpiredLiabilityReport(),
        removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          fromDate: fromDate,
          toDate: toDate,
        }),
      );
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
